@charset "utf-8";
@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css);

.App,
.App input,
.App button,
.App textarea {
  font-family: "cwTeXFangSong", "serif";
}

:root {
  /*
  https://paletton.com/#uid=31Q0U0klllldTYjhBvzp4b7sN0U
  */
  --primary: #fbf971;
  --primary-light: #fffd90;
  --primary-dark: #aaa839;
  --secondary: #5570a8;
  --secondary-light: #88a7f0;
  --secondary-dark: #2e4172;
  --background: #eef1f2;

  --footer-background: var(--secondary-light);

  --navbar-item: var(--primary-dark);
  --navbar-item-text: #070701;
  --navbar-item-hover: var(--primary);
  // do a linear gradient for active item
  --navbar-item-active-top: var(--primary-light);
  --navbar-item-active-bottom: var(--primary-dark);
}

body {
  min-height: 100vh; // make footer stick to bottom
}

$body-background-color: var(--background);

@import "node_modules/bulma/bulma.sass";
@import "node_modules/bulma-helpers/bulma-helpers.sass";
