.Footer {
  padding-top: 1rem;

  .footer {
    color: var(--background);
    background: var(--footer-background);
    padding: 1rem 1rem;
  }

  .flex-row {
    align-items: center;

    img {
      margin: 0.5rem;
      width: 2rem;
    }
    img.is-larger {
      margin: 1rem;
      width: 4.5rem;
    }
    img + span {
      text-align: left;
    }
  }
}
