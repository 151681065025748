.Header {
  position: sticky;
  top: 0;
  // make header higher than content
  z-index: 900;
  // blends with main content when it scrolls
  background-color: var(--background);

  // this makes the second row falls to the next line
  .navbar {
    // reset Bulma setting
    background-color: inherit;
    flex-wrap: wrap;
  }

  // first row, two nav-items plus hamburger
  .navbar-brand {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    img {
      max-height: 100%;
      object-fit: contain;
    }

    img.qefImg {
      height: 2.8rem;
      max-width: 10vw;
      padding-left: 8px;
    }

    img.bannerImg {
      height: 5rem;
      max-width: 60vw;
    }

    @media only screen and (max-width: 720px) {
      img.qefImg {
        height: 2.2rem;
        max-width: 8vw;
      }
    }
  }

  // second row, standard nav-items, providing data to hamburger
  .navbar-menu {
    margin-top: 4px;
    margin-bottom: 8px;
    // reset Bulma setting
    background-color: inherit;

    .navbar-start {
      flex-grow: 1;
      justify-content: space-around;
    }

    .navbar-item {
      font-size: 1.2rem;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      // to override .is-active:not(:focus):not(:hover)
      flex-grow: 1;
      color: var(--navbar-item-text);
      background: var(--navbar-item);
      margin: 4px;
      box-shadow: 5px -4px 10px 0px rgba(0, 0, 0, 0.5);
    }

    .navbar-item.is-active {
      background-image: linear-gradient(
        to bottom,
        var(--navbar-item-active-top),
        var(--navbar-item-active-bottom)
      );
    }

    .navbar-item:hover {
      background: var(--navbar-item-hover) !important;
      transform: translate(0, -8px);
      transition: transform 0.5s;
    }
  }
}
