.Typist .Character {
  position: relative;
  animation: move-text-color 0.9s forwards;
  bottom: -1em;
  opacity: 0;
}

@keyframes move-text-color {
  0% {
    bottom: -0.2em;
    opacity: 0.2;
  }

  50% {
    bottom: 0.1em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}
