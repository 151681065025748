.Home {
  position: relative;
  background-color: white;

  // https://stackoverflow.com/a/29846400/665507
  @mixin delayed-fade-in($waitTime, $fadeTime) {
    animation: wait #{$waitTime}, fade-in #{$fadeTime} #{$waitTime};
  }

  @keyframes wait {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      // transform: translateX(-120%);
    }
    100% {
      opacity: 1;
      // transform: translateX(0);
    }
  }

  .was_sketch {
    max-width: 80%;

    @media screen and (min-width: 769px) {
      // @include delayed-fade-in("2s", "5s");
    }
  }

  .TextBox {
    width: 100%;
    font-size: 1.5rem;

    // desktop: vertical text
    @media screen and (min-width: 769px) {
      writing-mode: vertical-rl;
      text-orientation: upright;
      height: 500px;
    }

    p {
      line-height: 1.7;
    }
  }
}
